// Import Dependencies
import styled from 'styled-components';

// Import vars
import { breakpoint, colors, layer } from '../../../utils/styles';

const MobileNav = styled.header`
  background-color: ${colors.white};
  bottom: 0;
  height: 62px;
  left: 0;
  margin: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  overflow: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  position: fixed;
  width: 100%;
  z-index: ${layer.nav};

  ${breakpoint.medium`
    display: none;
  `};
`;

export default MobileNav;

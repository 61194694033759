// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import NavItem from '../NavItem';

// Import Styled Components
import StyledNav from '../../styles/components/Nav';

const Nav = ({ items, ...props }) => (
  <StyledNav {...props}>
    <ul>
      {items &&
        items.map(
          (item, index) =>
            item && (
              <NavItem
                key={item.id}
                index={index}
                {...props}
                {...item}
                disabled={item.disabled ?? props.disabled}
              />
            )
        )}
    </ul>
  </StyledNav>
);

Nav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Nav;
